import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Context } from "../../context/Store";
import instance from "../../../axios/axios";
import { addBannerApi, addBlogApi, changeReadStatus } from "../../../axios/api";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";


function ViewEnquiryModal({ isModal, setModal, obj}) {

  useEffect(() => {
    if(obj?.id){
      instance.post(changeReadStatus+obj?.id)
      .then((response)=>{
          const { StatusCode , data}  = response.data;
          if(StatusCode === 6000){
         
          }else{
        }
      })
    }
  }, [isModal])
  

  return (
    <Container className={isModal && "active"}>
      <Overlay onClick={() => setModal(false)}></Overlay>
      <Modal>
        <Containers>
          <Head>Enquiry Information</Head>
          <Section>
          <Cover>
             <Title>
                Date
             </Title>
             <Content>
                {obj?.enquiry_date.split('T')[0]}
             </Content>
          </Cover>
          <Cover>
             <Title>
                Name
             </Title>
             <Content>
                {obj?.name}
             </Content>
          </Cover>
          <Cover>
             <Title>
                Email
             </Title>
             <Content>
                {obj?.email}
             </Content>
          </Cover>
          <Cover>
             <Title>
                Phone
             </Title>
             <Content>
                {obj?.phone}
             </Content>
          </Cover>
          <Cover>
             <Title>
                Message
             </Title>
             <Content>
                {obj?.message}
             </Content>
          </Cover>
          {
            obj?.upload_plan && (
              <Cover >
              <Title>
                 Plan 
              </Title>
              <Content>
                  <Btn>
                     <Atag target="_blank" href={obj?.upload_plan} download={`plan.${obj?.upload_plan.split('.')[1]}`}>
                       Plan
                     </Atag>
                  </Btn>
              </Content>
              </Cover>
            )
          }
          </Section>
          
         
        </Containers>
      </Modal>
    </Container>
  );
}
export default ViewEnquiryModal;

const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;

const Modal = styled.div`
  width: 90%;
  max-width: 736px;
  max-height: 100vh;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  background: #232327;
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 0px 3px 56px #000;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  @media all and (max-width: 1380px) {
    left: 58%;
  }
  @media all and (max-width: 1280px) {
    width: 550px;
  }
  @media all and (max-width: 1080px) {
    width: 500px;
  }
  @media all and (max-width: 980px) {
    width: 450px;
  }
  @media all and (max-width: 768px) {
    width: 400px;
  }
  @media all and (max-width: 640px) {
    width: 350px;
  }
  @media all and (max-width: 480px) {
    width: 330px;
  }
  @media all and (max-width: 360px) {
    width: 300px;
  }
`;

const Containers = styled.div`
  padding: 50px 80px;
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  border-radius: 10px;
  @media all and (max-width: 640px) {
    padding: 20px;
  }
`;

const Cover = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
  padding: 20px;
  width: 90%;
  margin: 0 auto;
`;


const Title = styled.h5`
  color : var(--textcolor);
  text-align:start;
  width: 100%;
`;

const Content = styled.div`
      display: flex;
      justify-content:flex-start;
      width:100%;
`;

const Head = styled.h2`
    color : var(--textcolor);
    width : 100%;
    text-align: center;
    padding :10px;
`;

const Section = styled.div`
      display:flex;
      flex-direction: column;
      gap: 10px;
      width : 100%;
      margin : 15px auto;
      background-color:var(--lightgrey);
`;

const Btn = styled.button`
      padding: 12px 25px;
      background-color: var(--lightblue);
      color:#fff;
      border:none;
      border-radius:5px;
`;

const Atag = styled.a`
    text-decoration: none;
    color:#fff;
`;


