import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import instance from "../../../axios/axios";
import {
  deleteService,
  deleteTestimonialApi,
  editService,
} from "../../../axios/api";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import CircularProgress from "@mui/material/CircularProgress";

function EditServiceModal({ isModal, setModal, item }) {
  const noCheckTags = [
    "image_prev",
    "service_banner_prev",
    "service_banner",
    "image",
  ];
  const initalState = {};
  const [formData, setformData] = useState(initalState);
  const [error, seterror] = useState("");
  const [loading, setloading] = useState(false);
  let validationError = false;
  const formRef = useRef();

  useEffect(() => {
    if (isModal) {
      setformData({
        name: item?.name,
        details: item?.details,
        description: item?.description,
        title: item?.title,
        image_prev: item?.image,
        image: "",
        service_banner_prev: item?.service_banner,
        service_banner: "",
        slug: item?.slug,
        meta_title: item?.meta_title,
        meta_description: item?.meta_description,
      });
    } else {
      setformData({});
    }
  },[item?.id, isModal]);

  const assignHandler = (e) => {
    e.preventDefault();
    const form = new FormData();

    Object.entries(formData).forEach((item) => {
      if (!noCheckTags.includes(item[0]) && item[1] === "") {
        seterror(`${item[0].replace("_", "-")} this field is required`);
        validationError = true;
        setTimeout(() => {
          seterror("");
        }, 3000);
      } else {
        form.append(item[0], item[1]);
      }
    });

    if (!validationError) {
      setloading(true);
      instance.post(editService + item.id, form).then((response) => {
        const { StatusCode, data } = response.data.app_data;
        if (StatusCode === 6000) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Service Edited !",
            showConfirmButton: false,
            timer: 1500,
            width: 600,
          });
          setloading(false);
          e.target.reset();
          setformData(initalState);
          setModal(false);
        } else if (StatusCode === 6001) {
          seterror(data.Message);
          setTimeout(() => {
            seterror("");
          }, 3000);
          setloading(false);
          scrollIntoView(formRef)
        }
      });
    }
  };

  const handleChange = (e) => {
    const { name } = e.target;
    validationError = false;
    if (name === "image" || name === "service_banner") {
      setformData({
        ...formData,
        [name]: e.target.files[0],
        [name + "_prev"]: URL.createObjectURL(e.target.files[0]),
      });
    } else {
      setformData({
        ...formData,
        [name]: e.target.value,
      });
    }
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Do you want to delete the service?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        instance.delete(deleteService + item.id).then((response) => {
          const { StatusCode, data } = response.data.app_data;
          if (StatusCode === 6000) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Service Deleted !",
              showConfirmButton: false,
              timer: 1500,
              width: 600,
            });
            setformData(initalState);
            setModal(false);
          } else if (StatusCode === 6001) {
            seterror(data.Message);
            setTimeout(() => {
              seterror("");
            }, 3000);
          }
        });
      }
    });
  };

  const handleText = (value) => {
    setformData({
      ...formData,
      description: value,
    });
  };

  const scrollIntoView = (ref) =>{
    ref.current.scrollIntoView();
  };

  return (
    <Container className={isModal && "active"}>
      <Overlay onClick={() => setModal(false)}></Overlay>
      <Modal>
        <Containers onSubmit={(e) => assignHandler(e)} ref={formRef}>
          {error && <Error>{error}</Error>}
          <Cover>
            <Label>Name</Label>
            <Input
              type="text"
              value={formData?.name}
              placeholder="Enter name"
              onChange={(e) => handleChange(e)}
              name="name"
              required
            />
          </Cover>
          <Cover>
            <Label>Details</Label>
            <TextArea
              type="text"
              value={formData?.details}
              placeholder="Enter a Breif detail"
              onChange={(e) => handleChange(e)}
              name="details"
              required
            />
          </Cover>
          <Cover>
            <Label>Title</Label>
            <Input
              type="text"
              value={formData?.title}
              placeholder="Enter Title name"
              onChange={(e) => handleChange(e)}
              name="title"
              required
            />
          </Cover>
          <Cover>
            <Label>Icon </Label>
            <CoverInput>
              <Preview>
                {formData?.image_prev && <img src={formData?.image_prev}></img>}
              </Preview>
              <Input
                // className="num"
                type="file"
                placeholder=""
                name="image"
                onChange={(e) => handleChange(e)}
              />
            </CoverInput>
          </Cover>
          <Cover>
            <Label>Banner</Label>
            <CoverInput>
              <Preview>
                {formData?.service_banner_prev && (
                  <img src={formData?.service_banner_prev}></img>
                )}
              </Preview>
              <Input
                // className="num"
                type="file"
                placeholder=""
                name="service_banner"
                onChange={(e) => handleChange(e)}
              />
            </CoverInput>
          </Cover>
          <Cover>
            <Label>Content</Label>
            <TextEditor>
              <ReactQuill
                className="rich-text-area"
                onChange={handleText}
                value={formData?.description}
              />
            </TextEditor>
          </Cover>
          <Cover>
            <Label>Slug</Label>
            <Input
              type="text"
              value={formData?.slug}
              placeholder="Enter the slug"
              onChange={(e) => handleChange(e)}
              name="slug"
              required
            />
          </Cover>
          <Cover>
            <Label>meta title</Label>
            <Input
              type="text"
              value={formData?.meta_title}
              placeholder="Enter the meta title"
              onChange={(e) => handleChange(e)}
              name="meta_title"
            />
          </Cover>
          <Cover>
            <Label>meta description</Label>
            <Input
              type="text"
              value={formData?.meta_description}
              placeholder="Enter the meta description"
              onChange={(e) => handleChange(e)}
              name="meta_description"
            />
          </Cover>
          <Covers>
            <Delete onClick={handleDelete}>Delete</Delete>
            <Submit type="submit">
              {loading ? <CircularProgress /> : "Submit"}
            </Submit>
          </Covers>
        </Containers>
      </Modal>
    </Container>
  );
}

export default EditServiceModal;

const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  max-width: 736px;
  max-height: 100vh;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  background: #232327;
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 0px 3px 56px #000;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  @media all and (max-width: 1380px) {
    left: 58%;
  }
  @media all and (max-width: 1280px) {
    width: 550px;
  }
  @media all and (max-width: 1080px) {
    width: 500px;
  }
  @media all and (max-width: 980px) {
    width: 450px;
  }
  @media all and (max-width: 768px) {
    width: 400px;
  }
  @media all and (max-width: 640px) {
    width: 350px;
  }
  @media all and (max-width: 480px) {
    width: 330px;
  }
  @media all and (max-width: 360px) {
    width: 300px;
  }
`;
const Select = styled.select`
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const CoverInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const Containers = styled.form`
  padding: 50px 80px;
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  border-radius: 10px;
  @media all and (max-width: 640px) {
    padding: 20px;
  }
`;
const Covers = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
`;
const Cover = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
`;
const Label = styled.div`
  color: var(--textcolor);
  width: 40%;
`;
const Input = styled.input`
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const TextArea = styled.textarea`
  padding: 10px 20px;
  width: 100%;
  height: 120px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
  resize: vertical;
`;
const Submit = styled.button`
  background-color: var(--lightblue);
  color: #000;
  width: 150px;
  height: 40px;
  font-family: "mont-medium";
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Sub = styled.div`
  display: flex;
  width: 60%;
`;
const Error = styled.p`
  padding: 0;
  margin: 10px 0;
  color: var(--red);
  width: 100%;
  text-align: center;
`;
const Preview = styled.div`
  width: 100%;
  margin: 10px 0;
  img {
    width: 100%;
    display: block;
  }
`;
const Delete = styled.div`
  margin: 0 10px;
  background-color: var(--red);
  color: #ffffff;
  width: 150px;
  height: 40px;
  font-family: "mont-medium";
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PreviewImg = styled.div`
  img {
    width: 200px;
    height: auto;
  }

  video {
    width: 250px;
    height: auto;
  }
`;
const TextEditor = styled.div`
  padding: 10px 20px;
  width: 100%;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
  .ql-toolbar {
    background-color: #fff;
  }
`;
