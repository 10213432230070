import React, { useContext, useEffect, useState } from "react";
import HeadTopBar from "../../includes/HeadTopBar";
import { styled } from "styled-components";

import { BannerData } from "../../general/dummyData";


import instance from '../../../axios/axios';
import { BannerStatusToggleApi, bannerListApi } from "../../../axios/api";
import Nodata from "../../includes/noData/Nodata";
import Swal from 'sweetalert2';
import AddBannerModal from "../../modal/banner/AddBannerModal";
import EditBannerModal from "../../modal/banner/EditBannerModal";
import CircularProgress from "@mui/material/CircularProgress";
import editBox from '../../../assets/images/icons/edit.svg'

function Banners() {
  const [isModal, setModal] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [editObj, seteditObj] = useState('');

  const [banners, setbanners] = useState(null);
  const [state, setstate] = useState(false);



  useEffect(() => {
    if(!editModal){
      seteditObj('');
    }
  }, [editModal]);

  useEffect(() => {
     instance
     .get(bannerListApi)
     .then((response)=>{
        
        const { StatusCode, data } = response.data.app_data;
        
        if(StatusCode === 6000){
          
        if(data.length === 0){
            setbanners([])
        }else{
          setbanners(data);
        }
        
        }else{
          setbanners([])
        }
      }).catch(()=>{})
  }, [isModal,editModal,state]);

  const updateStatus = (id)=>{
        instance.put(BannerStatusToggleApi+id)
        .then((response)=>{
          const { StatusCode, data } = response.data.app_data;
          if(StatusCode === 6000){
             setstate(!state)
             Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Status Changed',
              showConfirmButton: false,
              timer: 1500,
              height:100,
              width:400,
              fontSize:"14px",
            })
          }else{
            Swal.fire({
              position: 'top-end',
              icon: 'Error',
              title: 'Something went wrong',
              showConfirmButton: false,
              timer: 1500,
              height:100,
              width:400,
              fontSize:"14px",
            })
          }
        }).catch(()=>{})
  }

  return (
    <Container>
      <Wrapper className="wrapper">
        <HeadTopBar />
        <ProgramSection>
          <TopSection>
            <Title>Banners</Title>
            <Button
              onClick={() => {
                setModal(true);
              }}
            >
              Add Banner
            </Button>
          </TopSection>
          {
            banners === null ?
            <Loader>
               <CircularProgress/>
            </Loader>
           : (
            <>
             {banners.length === 0 ? (
                <Nodata/>
              ):(
                <Table>
            <Thead>
              <Tr>
                <Th>SI.no</Th>
                <Th>Title</Th>
                <Th>Content</Th>
                <Th>Type</Th>
                <Th>Edit</Th>
              </Tr>
            </Thead>
            <Tbody>
              {banners?.map((obj, indx) => {
                return (
                  <Tr>
                    <Td>{indx + 1}</Td>
                    <Td>{obj.title}</Td>
                    <Td><p>{obj.content}</p></Td>
                    <Td>
                      <StatusBtn onClick={()=>updateStatus(obj.id)} status={obj.status} >{obj.status ? "Hide" : "Show"}</StatusBtn>
                    </Td>
                    <Td
                      img={true}
                    >
                      <Img 
                       onClick={()=>{
                        seteditObj(obj)
                        seteditModal(true)
                       }}
                       src={editBox} />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
            </Table>
              )}
            
            </>
            )
          }
        </ProgramSection>
        
        <AddBannerModal isModal={isModal} setModal={setModal}  />

        <EditBannerModal isModal={editModal} item={editObj} setModal={seteditModal}/>
      </Wrapper>
    </Container>
  );
}

export default Banners;

const Container = styled.div`
  padding: 80px 0;
  color: var(--textcolor);
`;
const Wrapper = styled.div``;
const ProgramSection = styled.div`
  margin-top: 40px;
`;
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Title = styled.h3`
  color: #fff;
`;
const Button = styled.div`
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  color: var(--lightblue);
  height: 40px;
  width: 150px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Cards = styled.div`
`;
const Card = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 10px;
`;
const Number = styled.div`
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  width: 10%;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
`;
const TextBar = styled.div`
  display: flex;
  width: 20%;
  padding: 0 40px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
`;
const Icon = styled.div`
  width: 18px;
  cursor: pointer;
`;
const Edit = styled.div`
  background-color: var(--darkgrey);
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--lightblue);
`;

const Image = styled.div`
  width: 30%;
  background-color: var(--darkgrey);
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 60%;
    display: block;
  }
`;
const Status = styled.div`
  width: 20%;
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props)=> props.active === true ? 'background-color: var(--lightblue);color:#fff;' : 'background-color: var(--darkgrey);color:var(--lightblue);'};
  cursor: pointer;
`;

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
`;

const Table = styled.table`
  width: 100%;
  border-radius: 10px;
`;

const Thead = styled.thead`
  background-color: #5a5d61;
  color: #fff;
  padding: 5px;
`;

const Tr = styled.tr`
  border-radius: 5px;
  cursor: pointer;
  background-color: #5e5e5e;

  &:hover {
    background-color: #757575;
  }

`;

const Th = styled.th`
  font-size: 16px;
  text-transform: capitalize;
  padding: 15px;
`;

const Tbody = styled.tbody`
  color: #fff;
`;

const Td = styled.td`
  text-align: center;
  padding: 8px;
  ${(props) =>
    props.img &&
    `
    display: flex;
    align-items: center;
    justify-content: center;
    `
    
  }

  i {
    color: var(--red);
  }

  p{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width:12ch;
    margin: 0 auto;
  }
`;

const Img = styled.img`
    width:20px;
    aspect-ratio: 1/3;
    display: block;
`;


const StatusBtn = styled.button`
    padding: 15px;
    border:none;
    width:100px;
    color: var(--lightblue);
    border-radius:5px;
    ${(props)=>{
      return props.status ? "background-color:var(--textcolor);color: var(--darkgrey);" : "background-color:var(--lightblue);color: #fff;"
      }}
    cursor: pointer;
`;
