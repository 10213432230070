import { SETPOINTS, SET_ARROW_POINTS, TOGGLE_STATE, ToggleMasterPlanStatus, UPDATEPOINTS, UPDATE_ACTIVE_TAB, UPDATE_ARROW_STATE, UPDATE_CURRENT_POINT, UPDATE_LEFT_POINT, UPDATE_TOP_POINT } from "./types";


const Reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_USER_DATA":
        const user_data = { ...state.user_data, ...action.user_data };
        localStorage.setItem("user_data", JSON.stringify(user_data));
        return {
          ...state,
          user_data: user_data,
        };
    case ToggleMasterPlanStatus :
         return{
          ...state,
          master_plan:{
            ...state.master_plan,
            active:!state.master_plan.active
          }
         };
        break;
    case UPDATE_TOP_POINT :
          return{
            ...state,
            master_plan:{
              ...state.master_plan,
              points:{
                ...state.master_plan.points,
                top:action.payload,
              }
            }
          };
    
    case UPDATE_LEFT_POINT :
          return{
            ...state,
            master_plan:{
              ...state.master_plan,
              points:{
                ...state.master_plan.points,
                left:action.payload,
              }
            }
            };
    case SETPOINTS :
      return{
        ...state,
        master_plan:{
          ...state.master_plan,
          allpoints:action.payload
        }
        };
    
    case UPDATE_CURRENT_POINT :
      return{
        ...state,
        master_plan:{
          ...state.master_plan,
          currentPoint:action.payload !== null ? parseInt(action.payload) : null
        }
        };
    case UPDATE_ACTIVE_TAB :
      return {
        ...state,
        master_plan:{
          ...state.master_plan,
          activeTab:action.payload
        }
        };
    case UPDATE_ARROW_STATE :
      return {
        ...state,
        master_plan:{
          ...state.master_plan,
          arrows:action.payload === null ? !state.master_plan.arrows : action.payload
        }
        };

    case SET_ARROW_POINTS :
      return {
        ...state,
        master_plan:{
          ...state.master_plan,
          arrowPoints:action.payload
        }
        };

    case TOGGLE_STATE :
      return {
        ...state,
        master_plan:{
          ...state.master_plan,
          state:!state.master_plan.state
        }
        };

    default:
      return state;
  }
};

export default Reducer;
