import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import instance from "../../../axios/axios";
import {deleteProjectApi, editProjectApi, viewProjectImageApi, viewSingleProjectApi } from "../../../axios/api";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import HeadTopBar from "../../includes/HeadTopBar";
import { useNavigate, useParams } from "react-router-dom";
import AddImageModal from "../../modal/projects/images/AddImageModal";
import EditImageModal from "../../modal/projects/images/EditImageModal";


function EditProject() {
  const FileFields = ['top_image']
  
  const noCheckTags = ["meta_tag","meta_description",'top_image']
  
   const initalState = {
    project_name:'',
    project_description:'',
    location:'',
    meta_tag:'',
    meta_description:'',
    slug:'',
    project_type:'',
    top_image:''
   }
   
   const [formData, setformData] = useState(initalState);
   const [error, seterror] = useState("");
   const [loader, setloader] = useState(false);
   const [project, setproject] = useState({});
   const [state, setstate] = useState(false);
   const [isModal, setModal] = useState(false);
   const [isEditModal, setEditModal] = useState(false);
   const [editObj, seteditObj] = useState({});
   const [images, setimages] = useState([]);

   const { id } = useParams();
   const navigate = useNavigate();
   let validationError = false;

   useEffect(() => {
    instance
    .get(viewSingleProjectApi+id)
     .then((response)=>{
       const { StatusCode, data } = response.data.app_data;
       if(StatusCode === 6000){
       const item = data[0];
       if(data.length === 0){
           setproject({})
       }else{
        setproject(item)
        setformData({
         project_name:item?.project_name,
         project_description:item?.project_description,
         location:item?.location,
         meta_tag:item?.meta_tag,
         meta_description:item?.meta_description,
         slug:item?.slug,
         top_image:"",
         top_image_prev:item?.top_image,
         project_type:item?.project_type
       })
       }

       }else{
         setproject({})
       }
     })
   }, [id,state])

   useEffect(() => {
     instance.get(viewProjectImageApi + id)
     .then((response) =>{
      const { StatusCode, data } = response.data.app_data;
      if(StatusCode === 6000){
         if(data){
            setimages(data)
         }else{
           setimages([])
         }
      }else{
        setimages([])
      }
     })
   }, [state,id])


  const assignHandler = (e) => {
    e.preventDefault();
     const form = new FormData();
     
      Object.entries(formData).forEach((obj)=>{
        
        if(obj[1]===""){
          if(!noCheckTags.includes(obj[0])){
               validationError = true
               seterror(`${obj[0]} - this field is required`);
               setTimeout(() => {
                  seterror("");
                }, 3000);
                }
        }else{
          form.append(obj[0],obj[1]);
        }
      })
      
      if(!validationError){
        setloader(true)
        instance.put(editProjectApi+project?.id,form)
        .then((response)=>{
          const { StatusCode , data} = response.data.app_data;
          setloader(false)
          if(StatusCode === 6000){
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Project Details updated !',
              showConfirmButton: false,
              timer: 1500,
              width:600,
            })
            e.target.reset();
            setformData(initalState);
            setstate(!state);
          }else if(StatusCode === 6001){
              seterror(data.message)
              setTimeout(() => {
                seterror("");
              },3000);
          }
        })
      }
  };
  
  const handleChange = (e) =>{
       const { name} = e.target;
       validationError=false;
       
       if(!FileFields.includes(name)){
        setformData({
            ...formData,
            [name] : e.target.value
          })
       }else{
        const field = `${name}_prev`
        if(e.target.files[0]){
          setformData({
            ...formData,
            [name] : e.target.files[0],
            [field]:URL.createObjectURL(e.target.files[0]),
          })
        }else{
          setformData({
            ...formData,
            [name] : e.target.files[0],
            [field]:"",
          })
        }
       }
  };

  const handleDelete = ()=>{
    Swal.fire({
      title: 'Do you want to delete the project?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        instance.delete(deleteProjectApi+project?.id)
        .then((response)=>{
          const { StatusCode , data} = response.data.app_data;
          if(StatusCode === 6000){
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Project Deleted !',
              showConfirmButton: false,
              timer: 1500,
              width:600,
            })
            setformData(initalState);
            navigate('/projects/')
          }else if(StatusCode === 6001){
              seterror(data.Message)
              setTimeout(() => {
                seterror("");
              },3000);
          }
        })
      }
    })
  }


  return (
    <Container>
      <Wrapper className="wrapper">
        <HeadTopBar />
        <ProgramSection>
          <TopSection>
            <Title>Edit Project - <span onClick={()=>navigate(-1)}> {formData.project_name}</span></Title>
            <Button type={"delete"}
              onClick={() => {
                 handleDelete()
              }}
            >
              Delete
            </Button>
            <Button
              onClick={() => {
                navigate('/assets/'+project?.id)
              }}
            >
              Assets
            </Button>
          </TopSection>
          <Containers onSubmit={(e)=>assignHandler(e)}>
          <Cover>
            <Label>Project Name</Label>
            <Input
              type="text"
              placeholder="Enter Project name"
              onChange={(e)=>handleChange(e)}
              name={"project_name"}
              value={formData.project_name}
            />
          </Cover>
          <Cover>
            <Label>Project Description</Label>
            <TextArea
              name={"project_description"}
              onChange={(e)=>handleChange(e)}
              value={formData.project_description}
            ></TextArea>
          </Cover>
          <Cover>
            <Label>Project Location</Label>
            <Input
              type="text"
              placeholder="Enter the Project location "
              onChange={(e)=>handleChange(e)}
              name={"location"}
              value={formData?.location}
            />
          </Cover>

          <Cover>
            <Label>Project Type</Label>
            <Input
              type="text"
              placeholder="Enter the Project Type "
              onChange={(e)=>handleChange(e)}
              name={"project_type"}
              value={formData?.project_type}
            />
          </Cover>
           
          <Cover>
            <Label>Top image <br /> (1447 x 729)</Label>
            <PrevContainer>
            {
              formData?.top_image_prev &&
              <Preview>
                <img src={formData?.top_image_prev} alt="" />
              </Preview>
            }
            <Input
              type="file"
              // placeholder="Enter the Project Type "
              onChange={(e)=>handleChange(e)}
              name={"top_image"}
            />
            </PrevContainer>
          </Cover>
    
          <Cover>
            <Label>meta tag</Label>
            <Input
              type="text"
              placeholder="Enter meta tag"
              onChange={(e)=>handleChange(e)}
              name={"meta_tag"}
              value={formData.meta_tag}
            />
          </Cover>
          <Cover>
            <Label>meta description</Label>
            <Input
              type="text"
              placeholder="Enter meta description"
              onChange={(e)=>handleChange(e)}
              name={"meta_description"}
              value={formData.meta_description}
            />
          </Cover>
          <Cover>
            <Label>Url - slug</Label>
            <Input
              type="text"
              placeholder="Enter url slug"
              onChange={(e)=>handleChange(e)}
              name={"slug"}
              value={formData.slug}
            />
          </Cover>
        
          <Covers>
            <Submit
            type="submit"
            >
              {
                loader ? <CircularProgress/> : "Update"
              }
            </Submit>
          </Covers>
          {
            error && <Error>{error}</Error>
          }
        </Containers>
        </ProgramSection>
         
        <Images>
          <AddImage>
            <Name>
              Images (676 x 844)
            </Name>
            <AddImageButton onClick={()=>setModal(true)}>
                Add Image
            </AddImageButton>
          </AddImage>
          <Items>
              {
                images ? images.map((obj)=>{
                  return (
                    <Item>
                    <Img>
                        <img src={obj?.image} alt="" />
                    </Img>
                    <EditOption>
                      <Button onClick={()=>{
                        setEditModal(true)
                        seteditObj(obj)
                      }}>
                      <i class="ri-edit-fill"></i>Edit
                      </Button>
                    </EditOption>
                  </Item>
                  )
                }) : 
                "No Data Found"
              }
          </Items>

          <AddImageModal state={state} setstate={setstate} isModal={isModal} setModal={setModal} id={project?.id} />
          <EditImageModal state={state} setstate={setstate} isModal={isEditModal} setModal={setEditModal} item={editObj}  />
        </Images>
      </Wrapper>
    </Container>
  );
}
export default EditProject;



const Container = styled.div`
  padding: 80px 0;
  color: var(--textcolor);
`;
const Wrapper = styled.div``;

const ProgramSection = styled.div`
  margin-top: 40px;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h3`
  color: #fff;

  span{
    color:var(--lightblue);
    cursor: pointer;
  }
`;

const Button = styled.div`
  background-color: var(--lightblue);
  border: 1px solid var(--bordercolor);
  ${(props)=>props.type === 'delete' && `
      background-color:var(--red);
  `}
  color: #fff;
  height: 40px;
  width: 160px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Containers = styled.form`
  padding: 50px 80px;
  background-color: transparent;
  border: 1px solid var(--bordercolor);
  border:none;
  @media all and (max-width: 640px) {
    padding: 20px;
  }
`;

const Covers = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
`;
const Cover = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
`;
const Label = styled.div`
  color: var(--textcolor);
  width: 40%;
`;
const Input = styled.input`
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const TextArea = styled.textarea`
  padding: 10px 20px;
  width: 100%;
  height: 120px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const Submit = styled.button`
  background-color: var(--lightblue);
  color: #000;
  width: 150px;
  height: 40px;
  font-family: "mont-medium";
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    width: 25px !important;
    height: 25px !important;
    color: white;
  }
`;
const Sub = styled.div`
  display: flex;
  width: 60%;
`;

const Error = styled.p`
    padding: 0;
    margin: 10px 0;
    color: var(--red);
    width: 100%;
    text-align: center;
`;

const Preview = styled.div`
    width: 100%;
    margin-bottom: 10px;
    ${(props)=>props.bg && 'background-color:#fff;'}
    img{
      width: 100%;
      display: block;
    }
    video{
      width: 100%;
      display: block;
    }
`;

const Select = styled.select`
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const CoverInput = styled.div`
  width: 100%;
  p{
    margin:0;
    padding:0;
  }
`;
const PrevContainer = styled.div`
      width : 100%;
      flex-direction: column;
`;

const Images = styled.div`
    display: flex;
    flex-direction: column;
    gap:25px;
    padding: 25px;
    /* background-color: #5b5b5b; */
    border: 1px solid #5b5b5b;
    border-radius:15px;
`;
const AddImage = styled.div`
      display: flex;
      justify-content: space-between;
`;
const AddImageButton = styled.button`
      background-color: var(--lightblue);
      color: #000;
      width: 150px;
      height: 40px;
      font-family: "mont-medium";
      border-radius: 6px;
      cursor: pointer;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
`;
const Items = styled.div`
      display: flex;
      flex-direction: column;
      gap:15px;
      /* padding:10px; */
`;
const Item  = styled.div`
      display: flex;
      padding:10px;
      background-color: #c9c9c926;
      border-radius:10px;
      /* border: 1px solid #949494; */
`;

const Name = styled.div`
      font-size: 16px;
      color: #fff;
`;

const Img = styled.div`
      display:flex;
      flex:1;
      justify-content: center;
      align-items: center;

      img{
        width: 50%;
        height: auto;
        object-fit: fill;
      }
`;

const EditOption = styled.div`
      display:flex;
      flex:1;
      justify-content: center;
      align-items: center;
      color:var(--lightblue);
      cursor: pointer;
`;