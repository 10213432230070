import React, { useEffect, useState } from "react";
import HeadTopBar from "../../includes/HeadTopBar";
import { styled } from "styled-components";

import instance from '../../../axios/axios';
import { listServiceFaq } from "../../../axios/api";
import Nodata from "../../includes/noData/Nodata";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import AddFaqModal from "../../modal/services/faqs/AddFaqModal";
import EditFaqModal from "../../modal/services/faqs/EditFaqModal";


function ServiceFaq() {
  const [isModal, setModal] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [editObj, seteditObj] = useState('');
  const {id} = useParams();

  const [faqs, setfaqs] = useState(null);
  const [state, setstate] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if(!editModal){
      seteditObj('');
    }
  }, [editModal]);

  useEffect(() => {
     instance
     .get(listServiceFaq+id)
      .then((response)=>{
        const { StatusCode, data } = response.data.app_data;
        if(StatusCode === 6000){
        if(data.length === 0){
            setfaqs([])
        }else{
            setfaqs(data)
        }
        }else{
            setfaqs([])
        }
      })
  },[isModal,editModal,state]);

  return (
    <Container>
      <Wrapper className="wrapper">
        <HeadTopBar />
        <ProgramSection>
          <TopSection>
            <Title>Service Faqs</Title>
            <Button
              onClick={() => {
                setModal(true);
              }}
            >
              Add Faq
            </Button>
          </TopSection>
          <MiddleSection>
            {
              faqs === null ? 
              <Loader>
                <CircularProgress/>
              </Loader>
              : 
              (
                faqs?.length === 0 ? (
                  <Nodata/>
              ):(
                  <Table>
                  <Thead>
                      <Tr>
                          <Th>
                             SI.no
                          </Th>
                          <Th>
                             Name
                          </Th>
                          <Th>
                             action
                          </Th>
                      </Tr>
                  </Thead>
                  <Tbody>
                      {  
                        faqs?.map((obj,indx)=>{
                              return  (
                              <Tr 
                              >
                                  <Td>
                                    {indx+1}
                                  </Td>
                                  <Td
                                    >
                                    {obj.faq_question.length > 800 ? obj.faq_question.splice(0, 800) : obj.faq_question}
                                  </Td>
                                  <Td 
                                  onClick={()=>{
                                    seteditObj(obj)
                                    seteditModal(true);
                                  }}
                                  >
                                  <i class="ri-edit-fill"></i>
                                  </Td>
                              </Tr>
                              )
                        })
                      }
                  </Tbody>
              </Table>
              )
              )
            }
          </MiddleSection>
          <AddFaqModal isModal={isModal} setModal={setModal} id={id}/>
          <EditFaqModal isModal={editModal} setModal={seteditModal} item={editObj}/>
        </ProgramSection>

      </Wrapper>
    </Container>
  );
}
export default ServiceFaq;

const Container = styled.div`
  padding: 80px 0;
  color: var(--textcolor);
`;
const Wrapper = styled.div``;
const ProgramSection = styled.div`
  margin-top: 40px;
`;
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Title = styled.h3`
  color: #fff;
`;
const Button = styled.div`
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  color: var(--lightblue);
  height: 40px;
  width: 150px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Cards = styled.div``;
const Card = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 10px;
`;
const Number = styled.div`
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  width: 10%;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
`;
const TextBar = styled.div`
  display: flex;
  width: 20%;
  padding: 0 40px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
`;
const Icon = styled.div`
  width: 18px;
  cursor: pointer;
`;
const Edit = styled.div`
  background-color: var(--darkgrey);
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--lightblue);
`;

const Image = styled.div`
  width: 30%;
  background-color: var(--darkgrey);
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 60%;
    display: block;
  }
`;
const Status = styled.div`
  width: 20%;
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props)=> props.active === true ? 'background-color: var(--lightblue);color:#fff;' : 'background-color: var(--darkgrey);color:var(--lightblue);'};
  cursor: pointer;
`;

const MiddleSection = styled.div`
      width: 100%;
      padding:10px;
`;

const Table = styled.table`
   width: 100%;
   border-radius: 10px;
`;

const Thead = styled.thead`
   background-color:#5a5d61;
   color: #fff;
   padding:5px;
`;

const Tr = styled.tr`
   border-radius: 5px;
   cursor: pointer;
   background-color:#5e5e5e;
   &:hover{
    background-color:#757575;
    }
`;

const Th = styled.th`
    font-size:16px;
    text-transform: capitalize;
    padding:15px;
`;

const Tbody = styled.tbody`
   color: #fff;
`;

const Td = styled.td`
   text-align: center;
   padding:8px;
   ${
    (props)=>props.img && 
                       `
                        display: flex;
                        align-items: center;
                        justify-content: center;
                       `
   }

   img{
      width:150px;
      display: block;
   }

   i{
    color:var(--red);
   }
`;
const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
`;