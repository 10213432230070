import React, { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../../screens/login/Login";
import Dashboard from "../../screens/dashboard/Dashboard";
import Sidebar from "../../includes/sidebar/Sidebar";
import { styled } from "styled-components";
import Testimonials from "../../screens/testimonials/Testimonials";
import Enquiry from "../../screens/enquiry/Enquiry";
import Banners from "../../screens/banners/Banners";
import BlogList from "../../screens/blog/BlogList";
import Contact from "../../screens/contact/Contact";
import About from "../../screens/about/About";
import ProjectList from "../../screens/projects/ProjectList";
import EditProject from "../../screens/projects/EditProject";
import { Context } from "../../context/Store";
import AssetsList from "../../screens/projects/AssetsList";
import Factory from "../../screens/factory/Factory";
import EquipmentsList from "../../screens/factory/EquipmentsList";
import EquipmentsAssets from "../../screens/factory/EquipmentsAssets";
import FactoryVisitEnquires from "../../../components/screens/factoryvisitEnquiry/FactoryVisitEnquires";
import ServiceList from "../../screens/services/ServiceList";
import ServiceMedia from "../../screens/services/ServiceMedia";
import ServiceFaq from "../../screens/services/ServiceFaq";
import SeoList from "../../screens/seo/SeoList";
import Content from "../../screens/googleContents/Content";

function AppRouter() {
  const { state} = useContext(Context);

  return (
    <Container>
      <Left>
         <Sidebar />
      </Left>
      <Right>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/banners" element={<Banners/>} />
          <Route path="/factory" element={<Factory/>} />
          <Route path="/factory-equipments" element={<EquipmentsList/>} />
          <Route path="/factory-equipment-assets/:id" element={<EquipmentsAssets/>} />
          <Route path="/blog" element={<BlogList />} />
          <Route path="/services" element={<ServiceList/>} />
          <Route path="/service/media/:id" element={<ServiceMedia/>} />
          <Route path="/service/faq/:id" element={<ServiceFaq/>} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/enquiry" element={<Enquiry />} />
          <Route path="/factory_visit_enquires" element={<FactoryVisitEnquires/>} />
          <Route path="/seo" element={<SeoList/>} />
          <Route path="/contents" element={<Content/>} />

          <Route path="/projects" element={<ProjectList />} />
          <Route path="/edit-project/:id" element={<EditProject />} />
          <Route path="/assets/:id" element={<AssetsList />} />

          <Route path="/login" element={<Login />} />

        </Routes>
      </Right>
    </Container>
  );
}

export default AppRouter;
const Container = styled.div`
  height: 100vh;
  background: #232327;
  color: #fff;
  display: flex;
`;
const Left = styled.div`
  width: 16%;
  height: 100%;
  @media all and (max-width: 1440px) {
    width: 20%;
  }
`;
const Right = styled.div`
  width: 86%;
  max-height: 100vh;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;
