import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Context } from "../../context/Store";
import instance from "../../../axios/axios";
import { addBannerApi, addTestimonialApi } from "../../../axios/api";
import Swal from "sweetalert2";

function AddTestmonialModal({ isModal, setModal, type, id }) {
  const initalState = {
    name: "",
    title: "",
    content: "",
    image: "",
  };
  const imageTypeFields = ["name", "title", "content", "image"];
  const videoTypeFields = ["name", "title", "content"];
  const [formData, setformData] = useState({});
  const [error, seterror] = useState("");
  let validationError = false;

  // -----------------Add program------------------------
  const assignHandler = (e) => {
  
    e.preventDefault();
    const form = new FormData();
    
    if (type === "image") {
      
      imageTypeFields.forEach((obj) => {
        if (
          formData[obj] &&
          formData[obj] !== undefined &&
          formData[obj] !== ""
        ) {
          form.append(obj, formData[obj]);
        } else {
          validationError = true;
          seterror(`${obj} - this field is required`);
          setTimeout(() => {
            seterror("");
          }, 3000);
        }
      });
    } else {
      
      if (!formData.video && !formData.url_field) {
        
        validationError = true;
        seterror(`video or url - this field is required`);
        setTimeout(() => {
          seterror("");
        }, 3000);
      }else{
        formData.video ? form.append('video', formData['video']) : form.append('url_field', formData['url_field'])
      }

      videoTypeFields.forEach((obj) => {
        if (
          formData[obj] &&
          formData[obj] !== undefined &&
          formData[obj] !== ""
        ) {
          
          form.append(obj, formData[obj]);
        } else {
          validationError = true;
          seterror(`${obj} - this field is required`);
          setTimeout(() => {
            seterror("");
          }, 3000);
        }
      });
    }

    if (!validationError) {
      
      instance.post(addTestimonialApi,form).then((response) => {
        const { StatusCode, data } = response.data.app_data;
        if (StatusCode === 6000) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Testimonial Added !",
            showConfirmButton: false,
            timer: 1500,
            width: 600,
          });
          e.target.reset();
          setformData(initalState);
          setModal(false);
        } else if (StatusCode === 6001) {
          seterror(data.Message);
          setTimeout(() => {
            seterror("");
          }, 3000);
        }
      });

    }
  };

  const handleChange = (e) => {
    const { name } = e.target;
    validationError = false;
    if (name === "image" || name === "video") {
      setformData({
        ...formData,
        [name]: e.target.files[0],
      });
    } else {
      setformData({
        ...formData,
        [name]: e.target.value,
      });
    }
  };

  return (
    <Container className={isModal && "active"}>
      <Overlay onClick={() => setModal(false)}></Overlay>
      <Modal>
        <Containers onSubmit={(e) => assignHandler(e)}>
          {error && <Error>{error}</Error>}

          {type === "image" ? (
            <>
              <Cover>
                <Label>Name</Label>
                <Input
                  type="text"
                  placeholder="Enter Name"
                  onChange={(e) => handleChange(e)}
                  name="name"
                  value={formData?.name}
                />
              </Cover>
              <Cover>
                <Label>Title</Label>
                <TextArea
                  name="title"
                  onChange={(e) => handleChange(e)}
                  value={formData?.title}
                ></TextArea>
              </Cover>
              <Cover>
                <Label>content</Label>
                <TextArea
                  name="content"
                  onChange={(e) => handleChange(e)}
                  value={formData?.content}
                ></TextArea>
              </Cover>
              <Cover>
                <Label>Image (202 x 203)</Label>
                <CoverInput>
                  <Input
                    // className="num"
                    type="file"
                    placeholder=""
                    name="image"
                    onChange={(e) => handleChange(e)}
                  />
                </CoverInput>
              </Cover>
            </>
          ) : (
            <>
              <Cover>
                <Label>Name</Label>
                <Input
                  type="text"
                  placeholder="Enter Name"
                  onChange={(e) => handleChange(e)}
                  name="name"
                  value={formData?.name}
                />
              </Cover>
              <Cover>
                <Label>Title</Label>
                <TextArea
                  name="title"
                  onChange={(e) => handleChange(e)}
                  value={formData?.title}
                ></TextArea>
              </Cover>
              <Cover>
                <Label>content</Label>
                <TextArea
                  name="content"
                  onChange={(e) => handleChange(e)}
                  value={formData?.content}
                ></TextArea>
              </Cover>
              <Cover>
                <Label>Video (1920 x 1080)</Label>
                <CoverInput>
                  <Input
                    // className="num"
                    type="file"
                    placeholder=""
                    name="video"
                    onChange={(e) => handleChange(e)}
                  />
                </CoverInput>
              </Cover>
              <Cover>
                <Label>url</Label>
                <TextArea
                  name="url_field"
                  onChange={(e) => handleChange(e)}
                  value={formData?.url_field}
                ></TextArea>
              </Cover>
            </>
          )}

          <Covers>
            <Submit type="submit">Submit</Submit>
          </Covers>
        </Containers>
      </Modal>
    </Container>
  );
}
export default AddTestmonialModal;

const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  max-width: 736px;
  max-height: 100vh;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  background: #232327;
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 0px 3px 56px #000;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  @media all and (max-width: 1380px) {
    left: 58%;
  }
  @media all and (max-width: 1280px) {
    width: 550px;
  }
  @media all and (max-width: 1080px) {
    width: 500px;
  }
  @media all and (max-width: 980px) {
    width: 450px;
  }
  @media all and (max-width: 768px) {
    width: 400px;
  }
  @media all and (max-width: 640px) {
    width: 350px;
  }
  @media all and (max-width: 480px) {
    width: 330px;
  }
  @media all and (max-width: 360px) {
    width: 300px;
  }
`;
const Select = styled.select`
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const CoverInput = styled.div`
  width: 100%;
`;
const Containers = styled.form`
  padding: 50px 80px;
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  border-radius: 10px;
  @media all and (max-width: 640px) {
    padding: 20px;
  }
`;

const Covers = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
`;
const Cover = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
`;
const Label = styled.div`
  color: var(--textcolor);
  width: 40%;
`;
const Input = styled.input`
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const TextArea = styled.textarea`
  padding: 10px 20px;
  width: 100%;
  height: 120px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const Submit = styled.button`
  background-color: var(--lightblue);
  color: #000;
  width: 150px;
  height: 40px;
  font-family: "mont-medium";
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Sub = styled.div`
  display: flex;
  width: 60%;
`;

const Error = styled.p`
  padding: 0;
  margin: 10px 0;
  color: var(--red);
  width: 100%;
  text-align: center;
`;
