import React from "react";
import { useState } from "react";
import { styled } from "styled-components";
import { listGContent } from "../../../axios/api";
import { useEffect } from "react";
import HeadTopBar from "../../includes/HeadTopBar";
import instance from "../../../axios/axios";
import Nodata from "../../includes/noData/Nodata";
import CircularProgress from "@mui/material/CircularProgress";
import AddContentModal from "../../modal/googleContents/AddContentModal";
import EditContentModal from "../../modal/googleContents/EditContentModal";

function Content() {
  const [contents, setcontents] = useState([]);
  const [addModal, setaddModal] = useState(false);
  const [item, setitem] = useState('');
  const [editModal, seteditModal] = useState(false);
  
  useEffect(() => {
    if(!editModal){
      setitem('');
    }
  },[editModal]);


  useEffect(() => {
    instance.get(listGContent).then((response) => {
      const { StatusCode, data } = response.data.app_data;
      if (StatusCode === 6000) {
        setcontents(data);
      } else if (StatusCode === 6001) {
        setcontents([]);
      }
    });
  }, [addModal,editModal]);

  return (
    <Container>
      <Wrapper className="wrapper">
        <HeadTopBar />
        <ProgramSection>
          <TopSection>
            <Title>Contents for Google</Title>
            <CoverTop>
              <Button onClick={() => {setaddModal(true)}}>Add Data</Button>
            </CoverTop>
          </TopSection>
          <MiddleSection>
            {contents === null ? (
              <Loader>
                <CircularProgress />
              </Loader>
            ) : contents?.length === 0 ? (
              <Nodata />
            ) : (
              <Table>
                <Thead>
                  <Tr>
                    <Th>SI.no</Th>
                    <Th>path</Th>
                    <Th>action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {contents?.map((obj, indx) => {
                    return (
                      <Tr>
                        <Td>{indx + 1}</Td>
                        <Td>{obj.page}</Td>
                        <Td
                          onClick={() => {
                           setitem(obj)
                           seteditModal(true)
                          }}
                        >
                          <i class="ri-edit-fill"></i>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            )}
          </MiddleSection>
        </ProgramSection>
      </Wrapper>
      <AddContentModal isModal={addModal} setModal={setaddModal}/>
      <EditContentModal isModal={editModal} setModal={seteditModal} item={item}/>
    </Container>
  );
}

export default Content;

const Container = styled.div`
  padding: 80px 0;
  color: var(--textcolor);
`;
const Wrapper = styled.div``;
const CoverTop = styled.div`
  display: flex;
`;

const ProgramSection = styled.div`
  margin-top: 40px;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Title = styled.h3`
  color: #fff;
`;
const Button = styled.div`
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  color: var(--lightblue);
  height: 40px;
  width: 275px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  margin: 0 5px;
`;

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
`;

const MiddleSection = styled.div`
  width: 100%;
  padding: 10px;
`;

const Table = styled.table`
  width: 100%;
  border-radius: 10px;
`;

const Thead = styled.thead`
  background-color: #5a5d61;
  color: #fff;
  padding: 5px;
`;

const Tr = styled.tr`
  border-radius: 5px;
  cursor: pointer;
  background-color: #5e5e5e;
  &:hover {
    background-color: #757575;
  }
`;

const Th = styled.th`
  font-size: 16px;
  text-transform: capitalize;
  padding: 15px;
`;

const Tbody = styled.tbody`
  color: #fff;
`;

const Td = styled.td`
  text-align: center;
  padding: 8px;
  ${(props) =>
    props.img &&
    `
                        display: flex;
                        align-items: center;
                        justify-content: center;
                       `}

  img {
    width: 150px;
    display: block;
  }

  i {
    color: var(--red);
  }
`;
