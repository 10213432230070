import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import HeadTopBar from "../../includes/HeadTopBar";
import { Context } from "../../context/Store";
import Nodata from "../../includes/noData/Nodata";
import instance from "../../../axios/axios";
import CircularProgress from "@mui/material/CircularProgress";
import { listFactoryVisitEnquiry } from "../../../axios/api";


const FactoryVisitEnquires = () => {
  const [getEnquiries, setEnquiries] = useState(null);
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);

  const access_token = user_data.access_token;

  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "Enquires-",
      },
    });
  }, []);


  useEffect(() => {
    instance.get(listFactoryVisitEnquiry).then((response) => {
      const { StatusCode, data } = response.data.app_data;
      if (StatusCode === 6000) {
        setEnquiries(data);
      } else {
        setEnquiries([]);
      }
    });
  }, []);

  return (
    <Container>
      <Wrapper className="wrapper">
        <HeadTopBar />
        <ProgramSection>
          <TopSection>
            <Title>Factory Visit Enquiries</Title>
            <CoverTop>
            </CoverTop>
          </TopSection>
          <MiddleSection>
            {getEnquiries === null ? (
              <Loader>
                <CircularProgress />
              </Loader>
            ) : getEnquiries?.length === 0 ? (
              <Nodata />
            ) : (
              <Table>
                <Thead>
                  <Tr>
                    <Th>SI.no</Th>
                    <Th>Date</Th>
                    <Th>Name</Th>
                    <Th>Phone</Th>
                    <Th>Time</Th>
                    <Th>Number of people</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {getEnquiries?.map((obj, indx) => {
                    return (
                      <Tr col={true}>
                        <Td>{indx + 1}</Td>
                        <Td>{obj.date}</Td>
                        <Td>{obj.name}</Td>
                        <Td>{obj.phone}</Td>
                        <Td>{obj.time}</Td>
                        <Td>
                          {obj.number_of_people}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            )}
          </MiddleSection>
        </ProgramSection>
      </Wrapper>
    </Container>
  );
};

export default FactoryVisitEnquires;

const Container = styled.div`
  padding: 80px 0;
  color: var(--textcolor);
`;
const Wrapper = styled.div``;

const CoverTop = styled.div`
  display: flex;
`;

const SearchBar = styled.div`
  border: 1px solid #5a5d61;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 8px;
  margin-right: 20px;
`;

const Input = styled.input`
  background-color: transparent;
  outline: none;
  border: none;
  height: 40px;
  color: #fff;
  padding: 0 20px;
  border-radius: 8px;
`;

const SearchIcon = styled.div`
  position: relative;
  &::before {
    position: absolute;
    content: "|";
    color: #5a5d61;
    left: -10px;
  }
`;

const ProgramSection = styled.div`
  margin-top: 40px;
`;
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Title = styled.h3`
  color: #fff;
`;
const Button = styled.div`
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  color: var(--lightblue);
  height: 40px;
  width: 180px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Cards = styled.div``;
const Card = styled.div`
  display: flex;
  gap: 10px;
  /* justify-content: space-between; */
  margin-bottom: 30px;
`;

const Delete = styled.div`
  background-color: var(--darkgrey);
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
`;

const CardTitles = styled.div`
  display: flex;
  /* justify-content: space-between; */
  gap: 10px;
  margin-bottom: 20px;
`;
const CardTit = styled.div`
  background-color: ${({ type }) =>
    type == "em"
      ? "transparent"
      : type == "ems"
      ? "transparent"
      : "var(--darkgrey)"};
  border: ${({ type }) =>
    type == "em"
      ? "none"
      : type == "ems"
      ? "none"
      : "1px solid var(--bordercolor)"};

  width: ${({ type }) =>
    type == "sl"
      ? "85px"
      : type == "name"
      ? "220px"
      : type == "user"
      ? "40%"
      : type == "phone"
      ? "180px"
      : type == "em"
      ? "85px"
      : type == "ems"
      ? "45px"
      : ""};
  display: flex;
  height: 40px;
  font-size: 14px;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0px 0 20px;
  box-sizing: border-box;
`;
const Edit = styled.div``;
const Name = styled.div`
  &::-webkit-scrollbar {
    display: none;
  }
  border: ${({ type }) =>
    type == "em"
      ? "1px solid var(--bordercolor)"
      : type == "ems"
      ? "1px solid var(--bordercolor)"
      : "1px solid #646464"};
  background-color: ${({ type }) =>
    type == "em" ? "var(--darkgrey)" : type == "ems" ? "var(--darkgrey)" : ""};
  width: ${({ type }) =>
    type == "sl"
      ? "85px"
      : type == "name"
      ? "220px"
      : type == "user"
      ? "40%"
      : type == "phone"
      ? "180px"
      : type == "em"
      ? "85px"
      : type == "ems"
      ? "45px"
      : ""};
  height: ${({ type }) => (type == "user" ? "150px" : "50px")};
  padding: ${({ type }) =>
    type == "ems" ? "0" : type == "sl" ? "0" : "0 0 0 15px"};
  justify-content: ${({ type }) =>
    type == "ems" ? "center" : type == "sl" ? "center" : "flex-start"};
  cursor: ${({ type }) =>
    type == "ems" ? "pointer" : type == "em" ? "pointer" : ""};

  display: flex;
  color: ${({ type }) => (type == "em" ? "#a35252" : "#ffffff8e")};
  border-radius: 8px;
  align-items: center;
  box-sizing: border-box;
  overflow: scroll;

  &.Button {
    color: red;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &.text {
    font-size: 14px;
    padding-top: 20px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  @media all and (max-width: 1440px) {
    font-size: 14px;
  }
`;
const ImageCover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 50%;
    display: block;
  }
`;

const Print = styled.div`
  display: flex;
  width: 100%;
`;

const PrintButton = styled.button`
  padding: 15px 20px;
  background-color: var(--lightblue);
  color: #fff;
  border: none;
  border-radius: 10px;
`;

const MiddleSection = styled.div`
  width: 100%;
  padding: 10px;
`;

const Table = styled.table`
  width: 100%;
  border-radius: 10px;
`;

const Thead = styled.thead`
  background-color: #5a5d61;
  color: #fff;
  padding: 5px;
`;

const Tr = styled.tr`
  border-radius: 5px;
  cursor: pointer;
  background-color: #5e5e5e;
  ${(props) => !props.isRead && props.col && "background-color:#a8a3a3;"}
  /* &:hover {
    background-color: #757575;
  } */
`;

const Th = styled.th`
  font-size: 16px;
  text-transform: capitalize;
  padding: 15px;
`;

const Tbody = styled.tbody`
  color: #fff;
`;

const Td = styled.td`
  text-align: center;
  padding: 8px;
  ${(props) =>
    props.img &&
    `
                        display: flex;
                        align-items: center;
                        justify-content: center;
                       `}

  img {
    width: 150px;
    display: block;
  }

  i {
    color: var(--red);
  }
`;

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
`;

const Img = styled.img`
  height: 20px;
  display: block;
`;
