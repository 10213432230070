import React, { useEffect, useState } from "react";
import styled from "styled-components";
import HeadTopBar from "../../includes/HeadTopBar";
import instance from "../../../axios/axios";
import Swal from 'sweetalert2';
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { editFactoryDetailsApi, viewFactoryDetailsApi } from "../../../axios/api";


function Factory() {
   const [formData, setformData] = useState({});
   const [objt, setobjt] = useState(null);
   const [error, seterror] = useState('');
   const [state, setstate] = useState(false);
   const navigate = useNavigate();
   
   useEffect(()=>{
   instance.get(viewFactoryDetailsApi)
   .then((response)=>{
    const { StatusCode,data} = response.data.app_data;
    setobjt(true)
    if(StatusCode === 6000){
      const item = data[0]
      setobjt(item)
      
      setformData({
         title:item?.title,
         body:item?.body,
         factory_banner:"null",
         preview:item?.factory_banner,
         machinery_content:item?.machinery_content,
         machinery_title:item?.machinery_title
      })

    }else{

      setformData({
         title:"",
         body:"",
         factory_banner:"",
         preview:"",
         machinery_content:"",
         machinery_title:""
     })

    }
   })
   },[state])

  const handleChange = (e)=>{
    const { name } = e.target;
    
    if(name === 'factory_banner'){
      setformData({
        ...formData,
        [name]:e.target.files[0],
        'preview':URL.createObjectURL(e.target.files[0])
       })
    }else{
     
      setformData({
        ...formData,
        [name]:e.target.value
       })
    }

  }

  const assignHandler = (e) => {
    e.preventDefault();
    const form = new FormData();
    
    Object.entries(formData).forEach((obj)=>{
      form.append(obj[0],obj[1]);
    })

    instance.post(editFactoryDetailsApi,form,{
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((response)=>{
      const { StatusCode, data } = response.data.app_data;
      if(StatusCode === 6000){
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Edited !',
          showConfirmButton: false,
          timer: 1500,
          height:100,
          width:400,
          fontSize:"14px",
        })
          e.target.reset();
          setstate(!state)
      }else{

        seterror(data.Message)
        setformData({})
        setstate(!state);
        setTimeout(()=>{
          seterror("")
        },2000)
      }

    })
  };

  
  return (
    <Container>
      <Wrapper className="wrapper">
        <HeadTopBar />
        <ProgramSection>
          <TopSection>
            <Title>Factory</Title>
            <Button2
            onClick={()=>{
              navigate('/factory-equipments')
            }}
            >
              Equipments
            </Button2>
          </TopSection>
          {
            objt === null ? 
             <Loader>
              <CircularProgress/>
             </Loader>
             : (
              <Section onSubmit={(e)=>{
                assignHandler(e);
              }}>
                <Cover>
                <Label>Title</Label>
                <TextArea placeholder="Title"
                 value={formData?.title}
                 name="title"
                 onChange={(e)=>handleChange(e)}
                 ></TextArea>
              </Cover>
              <Cover>
                <Label>Description</Label>
                <TextArea placeholder="Description"
                 value={formData?.body}
                 name="body"
                 onChange={(e)=>handleChange(e)}
                 ></TextArea>
              </Cover>
              <Cover>
                <Label>Factory Banner (1600 x 900)</Label>
                <PreviewImg>
                   {
                    formData?.preview &&
                    <img src={formData?.preview} alt="" />
                   }
                  <Input type="file"
                  name="factory_banner"
                  onChange={(e)=>handleChange(e)}
                   />
                </PreviewImg>
              </Cover>
              <Cover>
                <Label>Title - 2nd Section</Label>
                <TextArea placeholder="title"
                 value={formData?.machinery_title}
                 name="machinery_title"
                 onChange={(e)=>handleChange(e)}
                 ></TextArea>
              </Cover>
              <Cover>
                <Label>content</Label>
                <TextArea placeholder="content"
                 value={formData?.machinery_content}
                 name="machinery_content"
                 onChange={(e)=>handleChange(e)}
                 ></TextArea>
              </Cover>
              <Submit>
                  <SubmitButton
                  type="submit"
                  >
                       Update
                  </SubmitButton>
              </Submit>
              {
                error && <Error>
                  {error}
                </Error>
              }
            </Section>
            )
          }
      
        </ProgramSection>
      </Wrapper>
    </Container>
  );
}

export default Factory;

const Container = styled.div`
  padding: 80px 0;
  color: var(--textcolor);
`;
const Wrapper = styled.div``;

const ProgramSection = styled.div`
  margin-top: 40px;
`;
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;
const Title = styled.h3`
  color: #fff;
`;
const Button = styled.div`
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  color: var(--lightblue);
  height: 40px;
  width: 150px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Cards = styled.div``;
const Card = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 10px;
`;
const Number = styled.div`
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  width: 10%;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
`;
const TextBar = styled.div`
  display: flex;
  width: 20%;
  padding: 0 40px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
`;
const Icon = styled.div`
  width: 18px;
  cursor: pointer;
`;
const Edit = styled.div`
  background-color: var(--darkgrey);
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--lightblue);
`;

const Image = styled.div`
  width: 30%;
  background-color: var(--darkgrey);
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 60%;
    display: block;
  }
`;
const Status = styled.div`
  width: 20%;
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.active === true
      ? "background-color: var(--lightblue);color:#fff;"
      : "background-color: var(--darkgrey);color:var(--lightblue);"};
`;

const Section = styled.form``;

const Cover = styled.div`
  display: flex;
  margin: 15px 0px;
`;

const Label = styled.div`
  color: var(--textcolor);
  width: 40%;
`;
const Input = styled.input`
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;

const TextArea = styled.textarea`
  padding: 10px 20px;
  width: 100%;
  height: 120px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;

const PreviewImg = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  width: 100%;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
  img{
    width: 75%;
    display:block;
  }
`;

const Submit = styled.div`
 display: flex;
 margin: 15px 0px;
 align-items: flex-end;
 justify-content:flex-end;
`;

const SubmitButton = styled.button`
   background-color: var(--lightblue);
    color: var(--blue);
    width: 150px;
    height: 40px;
    font-family: "mont-medium";
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
`;

const Button2 = styled.div`
  background-color: var(--lightblue);
  border: 1px solid var(--bordercolor);
  ${(props)=>props.type === 'delete' && `
      background-color:var(--red);
  `}
  color: #fff;
  height: 40px;
  width: 160px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Error = styled.p`
      width:100%;
      color:red;
      text-align: center;
`;