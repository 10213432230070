import { BaseUrl } from "./axios";

export const bannerListApi = BaseUrl+"web/view-banner/";
export const addBannerApi = BaseUrl+"web/add-banner/";
export const editBannerApi = BaseUrl+"web/edit-banner/";
export const deleteBannerApi = BaseUrl+"web/delete-banner/";
export const BannerStatusToggleApi = BaseUrl+"web/hide-banner/";

export const GetProjectsCount = BaseUrl+"project/list-project/";


export const listEnquiry = BaseUrl+"project/view-enquiry/";
export const printEnquiry = BaseUrl+"project/download-enquiry/";
export const changeReadStatus = BaseUrl+"project/enquiry-read/";

export const listFactoryVisitEnquiry = BaseUrl+"factory/list-book-factory/";


export const viewHomeAbout = BaseUrl+"home/view-about/";
export const updateHomeAbout = BaseUrl+"home/edit-about/";


export const chiefLoginApi = BaseUrl+"general/chief-login/";
export const refreshTokenApi = BaseUrl+"token/refresh/";

export const addBlogApi = BaseUrl+"web/add-blog/";
export const listBlogApi = BaseUrl+"web/view-blog/";
export const deleteBlogApi = BaseUrl+"web/delete-blog/";
export const editBlogApi = BaseUrl+"web/edit-blog/";

export const addTestimonialApi = BaseUrl+"web/add-testimonials/";
export const listTestimonialApi = BaseUrl+"web/view-testimonials/";
export const editTestimonialApi = BaseUrl+"web/edit-testimonials/";
export const deleteTestimonialApi = BaseUrl+"web/delete-testimonials/";

export const listAwardsApi = BaseUrl+"home/view-award/";
export const addAwardApi = BaseUrl+"home/add-award/";
export const editAwardApi = BaseUrl+"home/edit-award/";
export const deleteAwardApi = BaseUrl+"home/delete-award/";


export const addAboutFeature = BaseUrl+"home/add-about-content-feature/";
export const listAboutFeature = BaseUrl+"home/view-about-content-feature/";
export const deleteAboutFeature = BaseUrl+"home/delete-about-content-feature/";


export const viewContactDetails = BaseUrl+"home/view-contactdetails/";
export const editContactDetails = BaseUrl+"home/edit-contactdetails/";


export const viewAboutDetails = BaseUrl+"home/view-about-content/";
export const editAboutDetails = BaseUrl+"home/edit-about-content/";


export const viewFactoryDetailsApi = BaseUrl+"factory/view-factory-banner/";
export const editFactoryDetailsApi = BaseUrl+"factory/edit-factory-banner/";



export const viewFactoryMachineListApi = BaseUrl+"factory/list-factory-assets/";
export const deleteFactoryMachineApi = BaseUrl+"factory/delete-factory-machine/";
export const editFactoryMachineApi = BaseUrl+"factory/edit-factory-machine/";
export const addFactoryMachineApi = BaseUrl+"factory/add-factory-machine/";


export const viewMachineAssets = BaseUrl+"factory/view-single-factory-assets/";
export const addMachineAssetPic = BaseUrl+"factory/add-factory-assets/";
export const addMachineAssetVideo = BaseUrl+"factory/add-factory-video/";
export const editMachineAssetPic = BaseUrl+"factory/edit-factory-assets/";
export const editMachineAssetVideo = BaseUrl+"factory/edit-factory-video/";

export const deleteMachineAssetPic = BaseUrl+"factory/delete-factory-assets/";
export const deleteMachineAssetVideo = BaseUrl+"factory/delete-factory-video/";



export const addProjectApi = BaseUrl+"project/add-project/";
export const viewProjectApi = BaseUrl+"project/view-project/";
export const editProjectApi = BaseUrl+"project/edit-project/";
export const viewSingleProjectApi = BaseUrl+"project/view-single-project/";
export const deleteProjectApi = BaseUrl+"project/delete-project/";

export const addProjectImageApi = BaseUrl+"project/add-project-image/";
export const viewProjectImageApi = BaseUrl+"project/view-project-image/";
export const editProjectImageApi = BaseUrl+"project/edit-project-image/";
export const deleteProjectImageApi = BaseUrl+"project/delete-project-image/";


export const viewAssets = BaseUrl+"project/view-single-project/";
export const addProjectAssetVideo = BaseUrl+"project/add-project-asset-video/";
export const addProjectAssetPic = BaseUrl+"project/add-project-asset/";
export const editAssetVideo = BaseUrl+"project/edit-project-asset-video/";
export const editAssetPic = BaseUrl+"project/edit-project-asset-video/";
export const deleteAssetVideo = BaseUrl+"project/delete-project-asset-video/";
export const deleteAssetPic = BaseUrl+"project/delete-project-asset/";

export const listCounters = BaseUrl+"home/count-enquiry/";

export const listServices = BaseUrl+"project/view-service/";
export const addService = BaseUrl+"project/add-service/";
export const editService = BaseUrl+"project/edit-service/";
export const deleteService = BaseUrl+"project/delete-service/";

export const viewServiceImages = BaseUrl+"project/list-service-images/";
export const addServiceImage = BaseUrl+"project/add-service-images/";
export const editServiceImage = BaseUrl+"project/edit-service-images/";
export const deleteServiceImage = BaseUrl+"project/edit-service-images/";

export const listServiceFaq = BaseUrl+"project/list-service-faq/";
export const addServiceFaq = BaseUrl+"project/add-service-faq/";
export const editServiceFaq = BaseUrl+"project/edit-service-faq/";
export const deleteServiceFaq = BaseUrl+"project/delete-service-faq/";
export const changeServiceStatus = BaseUrl+"project/hide-service/";

export const listSeoInfo = BaseUrl+"web/view-seo/";
export const addSeoInfo = BaseUrl+"web/add-seo/";
export const editSeoInfo = BaseUrl+"web/edit-seo/";
export const deleteSeoInfo = BaseUrl+"web/delete-seo/";


export const listGContent = BaseUrl+"web/view-googlecontent/";
export const addGContent = BaseUrl+"web/add-googlecontent/";
export const editGContent = BaseUrl+"web/edit-googlecontent/";
export const deleteGContent = BaseUrl+"web/delete-googlecontent/";

