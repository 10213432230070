import React, {useState } from "react";
import styled from "styled-components";
import instance from "../../../axios/axios";
import {
    addGContent,
} from "../../../axios/api";
import Swal from "sweetalert2";
import { useRef } from "react";
import ReactQuill from "react-quill";

function AddContentModal({ isModal, setModal, id }) {
  const initalState = {
    page: "",
    content: "",
  };

  const [formData, setformData] = useState({});
  const [error, seterror] = useState("");
  let validationError = false;
  const formref = useRef();

  const assignHandler = (e) => {
    e.preventDefault();
    const form = new FormData(formref.current);

    if(formData?.content?.length < 12){
        validationError = true;
        scrollIntoView(formref);
        seterror("Please provide a valid content");
          setTimeout(() => {
            seterror("");
        }, 3000);
        
    }else{
        form.append('content',formData?.content);
    }
    
    if (!validationError) {
      instance.post(addGContent,form).then((response) => {
        const { StatusCode, data } = response.data.app_data;
        if (StatusCode === 6000) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Added !",
            showConfirmButton: false,
            timer: 1500,
            width: 600,
          });
          e.target.reset();
          setformData(initalState);
          setModal(false);
          scrollIntoView(formref);
        } else if (StatusCode === 6001) {
          seterror(data.Message);
          setTimeout(() => {
            seterror("");
          }, 3000);
          scrollIntoView(formref);
        }
      });
    }
  };

  const handleChange = (e) => {
    const { name } = e.target;
    validationError = false;
    setformData({
      ...formData,
      [name]: e.target.value,
    });
  };

  const scrollIntoView = (ref) =>{
    ref.current.scrollIntoView();
  };

  const handleText = (value) => {
    setformData({
     ...formData,
     content:value
    })
 };

  return (
    <Container className={isModal && "active"}>
      <Overlay onClick={() => setModal(false)}></Overlay>
      <Modal>
        <Containers onSubmit={(e) => assignHandler(e)} ref={formref}>
          {error && <Error>{error}</Error>}

          <Cover>
            <Label>Path</Label>
            <Input
              type="text"
              placeholder="Enter the Page Relative Path"
              onChange={(e) => handleChange(e)}
              name="page"
              value={formData?.page}
              required
            />
          </Cover>
          <Cover>
            <Label>Content</Label>
          </Cover>
          <TextEditor>
              <ReactQuill
                className="rich-text-area"
                onChange={handleText}
                value={formData?.content}
              />
            </TextEditor>
          <Covers>
            <Submit type="submit">Submit</Submit>
          </Covers>
        </Containers>
      </Modal>
    </Container>
  );
}
export default AddContentModal;

const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 95%;
  max-width: 900px;
  max-height: 100vh;
  height: 90%;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  background: #232327;
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 0px 3px 56px #000;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  @media all and (max-width: 1380px) {
    left: 58%;
  }
  @media all and (max-width: 1280px) {
    width: 550px;
  }
  @media all and (max-width: 1080px) {
    width: 500px;
  }
  @media all and (max-width: 980px) {
    width: 450px;
  }
  @media all and (max-width: 768px) {
    width: 400px;
  }
  @media all and (max-width: 640px) {
    width: 350px;
  }
  @media all and (max-width: 480px) {
    width: 330px;
  }
  @media all and (max-width: 360px) {
    width: 300px;
  }
`;
const Containers = styled.form`
  height: 150%;
  padding: 50px 80px;
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  border-radius: 10px;
  @media all and (max-width: 640px) {
    padding: 20px;
  }
`;
const Covers = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
`;
const Cover = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
`;
const Label = styled.div`
  color: var(--textcolor);
  width: 40%;
`;
const Input = styled.input`
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const TextArea = styled.textarea`
  padding: 10px 20px;
  width: 100%;
  height: 120px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const Submit = styled.button`
  background-color: var(--lightblue);
  color: #000;
  width: 150px;
  height: 40px;
  font-family: "mont-medium";
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Sub = styled.div`
  display: flex;
  width: 60%;
`;
const Error = styled.p`
  padding: 0;
  margin: 10px 0;
  color: var(--red);
  width: 100%;
  text-align: center;
`;

const TextEditor = styled.div`
  padding: 10px 20px;
  width: 100%;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #f3f3f3;
  border: none;
  outline: none;
  color: #000000;
  height:80%;
  margin-bottom: 10px;
  .ql-toolbar{
    background-color:#fff;
  }

  .quill{
    height: 90%;
  }

  .ql-editor{
    height: 100% !important;
  }
`;
