import React, { useState } from "react";
import styled from "styled-components";
import instance from "../../../axios/axios";
import ReactQuill from "react-quill";
import { addService } from "../../../axios/api";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";


function AddServiceModal({ isModal, setModal, type, id }) {
  const [formData, setformData] = useState({});
  const [error, seterror] = useState("");
  const [loading, setloading] = useState(false);

  let validationError = false;

  const imageFields = ['image','service_banner']

  const assignHandler = (e) => {
    e.preventDefault();
    const form = new FormData();

    if(formData?.description?.length <= 0 ){
        seterror("Please enter a description");
        validationError = true;
        setTimeout(() => {
            seterror('')
        },2500);
    }
    Object.entries(formData).forEach((item)=>{
        form.append(item[0],item[1]);
    })

    if (!validationError) {
        setloading(true);
        instance.post(addService,form)
        .then((response)=>{
            const { StatusCode } = response.data.app_data;
            if(StatusCode === 6000){
               setformData({})
               setloading(false)
               e.target.reset()
               Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'service Added !',
                showConfirmButton: false,
                timer: 1500,
                width:600,
              })
              e.target.reset();
              setformData({});
              setModal(false);
            }else{
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'something went wrong',
                    showConfirmButton: false,
                    timer: 1500,
                    width:600,
                })
            }
        })
    }
  };

  const handleChange = (e) => {
    const { name } = e.target;
    validationError = false;
    
    if(imageFields.includes(name)){
        setformData({...formData
            ,[name] : e.target.files[0]})
    }else{
        setformData({
            ...formData,
            [name] : e.target.value
        })
    }
 
  };

  const handleText = (value) => {
    setformData({
     ...formData,
     description:value
    })
  };

  
  return (
    <Container className={isModal && "active"}>
      <Overlay onClick={() => setModal(false)}></Overlay>
      <Modal>
        <Containers
          onSubmit={(e) => {
            assignHandler(e);
          }}
        >
          {error && <Error>{error}</Error>}
            <>
          <Cover>
            <Label>Name</Label>
            <Input
              type="text"
              value={formData?.name}
              placeholder="Enter name"
              onChange={(e)=>handleChange(e)}
              name="name"
              required
            />
          </Cover>
          <Cover>
            <Label>Details</Label>
            <TextArea
             type="text"
             value={formData?.details}
             placeholder="Enter a Breif detail"
             onChange={(e)=>handleChange(e)}
             name="details"
             required
            />
          </Cover>
          <Cover>
            <Label>Title</Label>
            <Input
              type="text"
              value={formData?.title}
              placeholder="Enter Title name"
              onChange={(e)=>handleChange(e)}
              name="title"
              required
            />
          </Cover>
          <Cover>
            <Label>Icon </Label>
            <CoverInput>
              <Input
                // className="num"
                type="file"
                placeholder=""
                name="image"
                onChange={(e)=>handleChange(e)}
                required
              />
            </CoverInput>
          </Cover>
          <Cover>
            <Label>Banner </Label>
            <CoverInput>
              <Input
                // className="num"
                type="file"
                placeholder=""
                name="service_banner"
                onChange={(e)=>handleChange(e)}
                required
              />
            </CoverInput>
          </Cover>
          <Cover>
            <Label>Content</Label>
            <TextEditor>
              <ReactQuill
                className="rich-text-area"
                onChange={handleText}
                value={formData?.description}
              />
            </TextEditor>
          </Cover>
          <Cover>
            <Label>Meta Title</Label>
            <Input
              type="text"
              value={formData?.meta_title}
              placeholder="Enter meta title"
              onChange={(e)=>handleChange(e)}
              name="meta_title"
            />
          </Cover>
          <Cover>
            <Label>Meta Description</Label>
            <Input
              type="text"
              value={formData?.meta_description}
              placeholder="Enter the meta description"
              onChange={(e)=>handleChange(e)}
              name="meta_description"
            />
          </Cover>
          <Cover>
            <Label>Slug</Label>
            <Input
              type="text"
              value={formData?.slug}
              placeholder="Enter the slug"
              onChange={(e)=>handleChange(e)}
              name="slug"
              required
            />
          </Cover>
          </>
          <Covers>
            <Submit type="submit">{loading ? <CircularProgress/> : 'Submit'}</Submit>
          </Covers>
        </Containers>
      </Modal>
    </Container>
  );
}
export default AddServiceModal;

const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  max-width: 736px;
  max-height: 100vh;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  background: #232327;
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 0px 3px 56px #000;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  @media all and (max-width: 1380px) {
    left: 58%;
  }
  @media all and (max-width: 1280px) {
    width: 550px;
  }
  @media all and (max-width: 1080px) {
    width: 500px;
  }
  @media all and (max-width: 980px) {
    width: 450px;
  }
  @media all and (max-width: 768px) {
    width: 400px;
  }
  @media all and (max-width: 640px) {
    width: 350px;
  }
  @media all and (max-width: 480px) {
    width: 330px;
  }
  @media all and (max-width: 360px) {
    width: 300px;
  }
`;
const Select = styled.select`
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const CoverInput = styled.div`
  width: 100%;
`;
const Containers = styled.form`
  padding: 50px 80px;
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  border-radius: 10px;
  @media all and (max-width: 640px) {
    padding: 20px;
  }
`;

const Covers = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
`;
const Cover = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
`;
const Label = styled.div`
  color: var(--textcolor);
  width: 40%;
`;
const Input = styled.input`
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const TextArea = styled.textarea`
  padding: 10px 20px;
  width: 100%;
  height: 120px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
  resize: vertical;
`;
const Submit = styled.button`
  background-color: var(--lightblue);
  color: #000;
  width: 150px;
  height: 40px;
  font-family: "mont-medium";
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Sub = styled.div`
  display: flex;
  width: 60%;
`;

const Error = styled.p`
  padding: 0;
  margin: 10px 0;
  color: var(--red);
  width: 100%;
  text-align: center;
`;

const TextEditor = styled.div`
  padding: 10px 20px;
  width: 100%;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
  .ql-toolbar{
    background-color:#fff;
  }
`;
