import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import instance from "../../../axios/axios";
import { addBannerApi, addProjectApi } from "../../../axios/api";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";


function AddProjectModal({ isModal, setModal, type, id }) {
  const FileFields = ['top_image']
  const initalState = {
    project_name:'',
    project_description:'',
    location:'',
    meta_tag:'',
    meta_description:'',
    slug:'',
    top_image:'',
    project_type:'',
  }
   const [formData, setformData] = useState(initalState);
   const [error, seterror] = useState("");
   const [loader, setloader] = useState(false);
   let validationError = false;

  // -----------------Add program------------------------
  const assignHandler = (e) => {
     e.preventDefault();
     const form = new FormData();

      Object.entries(formData).forEach((obj)=>{
        
        if(obj[1]===""){
          
          validationError = true
          seterror(`${obj[0]} - this field is required`);
          setTimeout(() => {
            seterror("");
          }, 3000);
        }else{
          form.append(obj[0],obj[1]);
        }
      })
      
      if(!validationError){
        setloader(true)
        instance.post(addProjectApi,form)
        .then((response)=>{
          const { StatusCode , data} = response.data.app_data;
          setloader(true)
          if(StatusCode === 6000){
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Project Added !',
              showConfirmButton: false,
              timer: 1500,
              width:600,
            })
            setformData(initalState);
            setModal(false);
            setloader(false)
            e.target.reset();
          }else if(StatusCode === 6001){
              seterror(data.message)
              setTimeout(() => {
                seterror("");
              },3000);
              setloader(false)
          }
        })
      }
  };

  const handleChange = (e) =>{
       const { name} = e.target;
       validationError=false;
       
       if(!FileFields.includes(name)){
        setformData({
            ...formData,
            [name] : e.target.value
          })
       }else{
        setformData({
            ...formData,
            [name] : e.target.files[0]
          })
       }
  };

  return (
    <Container className={isModal && "active"}>
      <Overlay onClick={() => setModal(false)}></Overlay>
      <Modal>
        <Containers onSubmit={(e)=>assignHandler(e)}>
          <Cover>
            <Label>Project Name</Label>
            <Input
              type="text"
              placeholder="Enter Project name"
              onChange={(e)=>handleChange(e)}
              name={"project_name"}
              value={formData.project_name}
            />
          </Cover>
          <Cover>
            <Label>Description</Label>
            <TextArea
              name={"project_description"}
              onChange={(e)=>handleChange(e)}
              value={formData.project_description}
            ></TextArea>
          </Cover>
         
          <Cover>
            <Label>Project Location</Label>
            <Input
              type="text"
              placeholder="Enter the Project location"
              onChange={(e)=>handleChange(e)}
              name={"location"}
              value={formData.location}
            />
          </Cover>

          <Cover>
            <Label>Project type</Label>
            <Input
              type="text"
              placeholder="Enter the Project Type"
              onChange={(e)=>handleChange(e)}
              name={"project_type"}
              value={formData.project_type}
            />
          </Cover>

          <Cover>
            <Label>Top Image <br /> (1447 x 729)</Label>
            <Input
              type="file"
              placeholder=""
              onChange={(e)=>handleChange(e)}
              name={"top_image"}
              // value={formData.location}
            />
          </Cover>

          <Cover>
            <Label>meta tag</Label>
            <Input
              type="text"
              placeholder="Enter meta tag"
              onChange={(e)=>handleChange(e)}
              name={"meta_tag"}
              value={formData.meta_tag}
            />
          </Cover>
          <Cover>
            <Label>meta description</Label>
            <Input
              type="text"
              placeholder="Enter meta description"
              onChange={(e)=>handleChange(e)}
              name={"meta_description"}
              value={formData.meta_description}
            />
          </Cover>
          <Cover>
            <Label>Url - slug</Label>
            <Input
              type="text"
              placeholder="Enter url slug"
              onChange={(e)=>handleChange(e)}
              name={"slug"}
              value={formData.slug}
            />
          </Cover>
     
          <Covers>
            <Submit 
            type="submit"
            >
              {
                loader ? <CircularProgress/> : "Submit"
              }
            </Submit>
          </Covers>
          {
            error && <Error>{error}</Error>
          }
        </Containers>
      </Modal>
    </Container>
  );
}
export default AddProjectModal;

const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  max-width: 736px;
  max-height: 100vh;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  background: #232327;
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 0px 3px 56px #000;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  @media all and (max-width: 1380px) {
    left: 58%;
  }
  @media all and (max-width: 1280px) {
    width: 550px;
  }
  @media all and (max-width: 1080px) {
    width: 500px;
  }
  @media all and (max-width: 980px) {
    width: 450px;
  }
  @media all and (max-width: 768px) {
    width: 400px;
  }
  @media all and (max-width: 640px) {
    width: 350px;
  }
  @media all and (max-width: 480px) {
    width: 330px;
  }
  @media all and (max-width: 360px) {
    width: 300px;
  }
`;
const Select = styled.select`
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const CoverInput = styled.div`
  width: 100%;
`;
const Containers = styled.form`
  padding: 50px 80px;
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  border-radius: 10px;
  @media all and (max-width: 640px) {
    padding: 20px;
  }
`;

const Covers = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
`;
const Cover = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
`;
const Label = styled.div`
  color: var(--textcolor);
  width: 40%;
`;
const Input = styled.input`
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const TextArea = styled.textarea`
  padding: 10px 20px;
  width: 100%;
  height: 120px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const Submit = styled.button`
  background-color: var(--lightblue);
  color: #000;
  width: 150px;
  height: 40px;
  font-family: "mont-medium";
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    width: 25px !important;
    height: 25px !important;
    color: white;
  }
`;
const Sub = styled.div`
  display: flex;
  width: 60%;
`;

const Error = styled.p`
    padding: 0;
    margin: 10px 0;
    color: var(--red);
    width: 100%;
    text-align: center;
`;

