import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import HeadTopBar from "../../includes/HeadTopBar";
import Nodata from "../../includes/noData/Nodata";
import AddTestmonialModal from "../../modal/testimonial/AddTestmonialModal";
import { listTestimonialApi } from "../../../axios/api";
import instance from "../../../axios/axios";
import EditTestimonialModal from "../../modal/testimonial/EditTestimonialModal";
import CircularProgress from "@mui/material/CircularProgress";



const Testimonials = () => {
  const [testimonialModal, setTestimonialModal] = useState(false);
  const [isEditModal, setEditModal] = useState(false);
  const [selecteditem, setselecteditem] = useState('');
  const [getTestimonials, setTestimonials] = useState(null);
  const [type, settype] = useState('image');
  
 
  useEffect(() => {

   instance.get(listTestimonialApi)
   .then((response)=>{
    const { StatusCode, data} = response.data.app_data;
    if(StatusCode === 6000){
      setTestimonials(data)
    }else if(StatusCode === 6001){
      setTestimonials([])
    }
   });

  },[testimonialModal,isEditModal]);


  const chooseType = (type) =>{
      settype(type);
  };
  
  return (
    <Container>
      <Wrapper className="wrapper">
        <HeadTopBar />
        <ProgramSection>
          <TopSection>
            <Title>Testimonials</Title>
            <CoverTop>
              <Button onClick={() =>{
                 chooseType('video')
                 setTestimonialModal(true)
              }}>
                Add  Video Testimonial
              </Button>
              <Button onClick={() =>{
                 chooseType('image')
                 setTestimonialModal(true)
              }}>
                Add Image Testimonial
              </Button>
            </CoverTop>
          </TopSection>
          <MiddleSection>
            {
              getTestimonials === null ? 
              <Loader>
                <CircularProgress/>
              </Loader>
              : 
              (
                getTestimonials?.length === 0 ? (
                  <Nodata/>
              ):(
                  <Table>
                  <Thead>
                      <Tr>
                          <Th>
                             SI.no
                          </Th>
                          <Th>
                             Name
                          </Th>
                          <Th>
                             type
                          </Th>
                          <Th>
                             action
                          </Th>
                      </Tr>
                  </Thead>
                  <Tbody>
                      {  
                          getTestimonials?.map((obj,indx)=>{
                              return  (
                              <Tr 
                              >
                                  <Td>
                                     {indx+1}
                                  </Td>
                                  <Td>
                                     {obj.name}
                                  </Td>
                                  <Td>
                                     {
                                      obj.type === "image" ? "image": "video"
                                     }
                                  </Td>
                                  <Td 
                                   onClick={()=>{
                                     {
                                      obj.type === "image" ? chooseType('image') : chooseType('video')
                                     }
                                     setselecteditem(obj)
                                     setEditModal(true)
                                   }}
                                  >
                                  <i class="ri-edit-fill"></i>
                                  </Td>
                              </Tr>
                              )
                          })
                      }
                  </Tbody>
              </Table>
              )
              )
            }
          </MiddleSection>
        </ProgramSection>
        <AddTestmonialModal isModal={testimonialModal} type={type} setModal={setTestimonialModal}/>
        <EditTestimonialModal isModal={isEditModal} type={type}  setModal={setEditModal} item={selecteditem}/>
      </Wrapper>
    </Container>
  );

};

export default Testimonials;

const Container = styled.div`
  padding: 80px 0;
  color: var(--textcolor);
`;
const Wrapper = styled.div``;
const CoverTop = styled.div`
  display: flex;
`;
const SearchBar = styled.div`
  border: 1px solid #5a5d61;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 8px;
  margin-right: 20px;
`;
const Input = styled.input`
  background-color: transparent;
  outline: none;
  border: none;
  height: 40px;
  color: #fff;
  padding: 0 20px;
  border-radius: 8px;
`;
const SearchIcon = styled.div`
  position: relative;
  &::before {
    position: absolute;
    content: "|";
    color: #5a5d61;
    left: -10px;
  }
`;
const ProgramSection = styled.div`
  margin-top: 40px;
`;
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Title = styled.h3`
  color: #fff;
`;
const Button = styled.div`
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  color: var(--lightblue);
  height: 40px;
  width: 275px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  margin: 0 5px;
`;
const Cards = styled.div``;
const Card = styled.div`
  display: flex;
  gap: 10px;
  /* justify-content: space-between; */
  margin-bottom: 30px;
`;

const Delete = styled.div`
  background-color: var(--darkgrey);
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
`;


const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
`;

const MiddleSection = styled.div`
      width: 100%;
      padding:10px;
`;

const Table = styled.table`
   width: 100%;
   border-radius: 10px;
`;

const Thead = styled.thead`
   background-color:#5a5d61;
   color: #fff;
   padding:5px;
`;

const Tr = styled.tr`
   border-radius: 5px;
   cursor: pointer;
   background-color:#5e5e5e;
   &:hover{
    background-color:#757575;
    }
`;

const Th = styled.th`
    font-size:16px;
    text-transform: capitalize;
    padding:15px;
`;

const Tbody = styled.tbody`
   color: #fff;
`;

const Td = styled.td`
   text-align: center;
   padding:8px;
   ${
    (props)=>props.img && 
                       `
                        display: flex;
                        align-items: center;
                        justify-content: center;
                       `
   }

   img{
      width:150px;
      display: block;
   }

   i{
    color:var(--red);
   }
`;
