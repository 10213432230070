import React, { useContext, useState } from "react";

//images
import logo from "../../../assets/images/logo/logo.png";
import { styled } from "styled-components";
import bg from "../../../assets/images/login/loginBanner.jpg";
import { accountsConfig } from "../../../axiosConfig";
import { Context } from "../../context/Store";
import axios from 'axios'
import { chiefLoginApi } from "../../../axios/api";

function Login() {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [isVisible, setVisible] = useState(false);
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const [error, seterror] = useState('');
  
  const submitHandler = () => {
      const formData = new FormData();
      formData.append('username',username)
      formData.append('password',password)

      axios
      .post(chiefLoginApi,formData)
      .then((response)=>{
        const { StatusCode, data } = response.data.app_data;
        if(StatusCode === 6000){
          dispatch({
            type: "UPDATE_USER_DATA",
            user_data: {
              isLogin: true,
              access_token: data.access_token,
              refresh_token: data.refresh_token,
            },
          });
        }else if(StatusCode === 6001){
           seterror("Invalid Credentials");
           setTimeout(()=>{
            seterror('');
           },2500)
        }

      })
      
  };

  return (
    <Container>
      <Wrapper>
        <Content>
          <Left>
            <Logo>
              {/* <img src={logo} alt="logo" /> */}
            </Logo>
          </Left>
          <Right>
            <Form>
              <Title>LOGIN</Title>
              <Subtitle errorStatus={error}>
                {
                  error ? error : "Please enter your user name and password"
                }
              </Subtitle>
              <Input
                type="text"
                placeholder="User name"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <Cover>
                <Input
                  type={isVisible ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Eye onClick={() => setVisible(!isVisible)}>
                  {!isVisible ? (
                    <i class="ri-eye-line"></i>
                  ) : (
                    <i class="ri-eye-off-line"></i>
                  )}
                </Eye>
              </Cover>
              <Button onClick={() => submitHandler()}>Login</Button>
            </Form>
          </Right>
        </Content>
      </Wrapper>
    </Container>
  );
}

export default Login;

const Container = styled.div`
  height: 100vh;
`;
const Wrapper = styled.div``;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100vh;
`;
const Left = styled.div`
  background: url(${bg});
  width: 50%;
  background-size: cover;
`;
const Logo = styled.div`
  width: 20%;
  margin: 100px 0 0 150px;
`;
const Right = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Form = styled.div`
  width: 40%;
  margin: 0 auto;
  @media all and (max-width: 1440px) {
    width: 50%;
  }
`;
const Title = styled.h2`
  color: var(--blue);
  font-family: "mont-medium";
  font-size: 30px;
  text-align: center;
  margin-bottom: 30px;
`;
const Subtitle = styled.p`
 ${(props)=> props.errorStatus=== "" ? 'color:var(--blue)' :'color:var(--red)'};
  font-size: 14px;
  font-family: "mont-regular";
  text-align: center;
  margin-bottom: 40px;
`;
const Input = styled.input`
  height: 50px;
  width: 100%;
  padding-left: 30px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 30px;
  margin-bottom: 20px;
`;
const Cover = styled.div`
  position: relative;
`;
const Button = styled.div`
  background-color: var(--red);
  color: #fff;
  height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
`;
const Eye = styled.div`
  position: absolute;
  right: 20px;
  cursor: pointer;
  top: 15px;
`;
