export const ToggleMasterPlanStatus = "ToggleMasterPlanStatus";
export const UPDATE_TOP_POINT = "UPDATE_TOP";
export const UPDATE_LEFT_POINT = "UPDATE_LEFT";
export const SETPOINTS = "SET_POINTS";
export const UPDATE_CURRENT_POINT = "UPDATE_CURRENT_POINT";
export const UPDATE_ACTIVE_TAB = "UPDATE_ACTIVE_TAB";
export const UPDATE_ARROW_STATE = "UPDATE_ARROW_STATE";
export const SET_ARROW_POINTS = "SET_ARROW_POINTS";
export const TOGGLE_STATE = "TOGGLE_STATE";



